<template>
  <div>
    <CRow>
      <LoadingOverlay v-if="isFetchingGenericFilter" />
      <CCol md="12" class="m-1 pt-1">
        <GenericFilter
          :basicFilterlength="basicFilterlength"
          :filterSchema="currentPageSchema"
          :selectedFilters="selectedFilters"
          :filterDependencyObj="filterDependencyObj"
          :isFetchingFilter="isFetchingGenericFilter"
          :callback="updateRouterURL"
          :searchCallback="searchCallback"
          :filterAction="filterAction"
        />
        <CandidateCount
          :totalCount="getTotalCount"
          :filterCount="getCandidateCount"
          :enableDowload="true"
          @downloadResult="triggerCandidateListDownload"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol md="12" class="mb-5">
        <CRow>
          <CCol col="12"> </CCol>
          <CCol
            v-for="(item, index) in getCandidates"
            :key="index"
            sm="6"
            md="3"
          >
            <div v-if="isFetchingCandidates" class="skeleton-card"></div>
            <CandidateList v-else :item="item" />
          </CCol>
          <CCol
            class="d-flex justify-content-center"
            v-if="
              !isFetchingGenericFilter &&
              !isFetchingCandidates &&
              !getCandidates.length
            "
          >
            <h1>No Data found!</h1>
          </CCol>
        </CRow>
        <CRow
          v-if="!noMoreDataFromCandidateList"
          class="d-flex justify-content-around align-items-center"
          style="height: 200px"
        >
          <div class="has-loading-overlay">
            <LoadingOverlay v-if="isFetchingCandidates" />
          </div>
        </CRow>
        <div
          class="d-flex justify-content-center"
          v-else-if="getCandidates.length"
        >
          <span class="btn-pill btn-primary p-2"
            >No more records to display!</span
          >
        </div>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import CandidateList from "@/containers/CandidateList/CandidateList";
import CandidateCount from "@/components/Count";
import { mapGetters, mapActions } from "vuex";
import { getScope, Role } from "@/helpers/helper";
import GenericFilter from "@/components/reusable/GenericFilterAndSearch/index";

export default {
  name: "CandidateMain",
  data() {
    return {
      currentUserRole: getScope(),
    };
  },
  components: {
    CandidateList,
    CandidateCount,
    GenericFilter,
  },
  created() {
    this.setCurrentPageSchema({ schema: "newCandidateListSchema" });
  },
  mounted() {
    this.initAllFilters().then(async () => {
      await this.updateSelectedFilterFromFilterCode(
        this.$router.currentRoute.query
      );
      //Todo working on the two api calls issue
      this.candidatesListAction({
        query: this.getQueryResult.queryUrl,
      });
    });
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
    this.resetBackCount();
    //For setting getNextCandidateId and getPreviousCandidateId function according to current Page
    let payload = {
      currentPage: this.$router.currentRoute.name,
    };
    this.setCurrentNavigationCandidatesPage(payload);
    this.$store.commit("RESET_CANDIDATE_LIST_BACK_URL");
  },
  beforeDestroy() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
  computed: {
    ...mapGetters([
      "getCandidates",
      "isFetchingCandidates",
      "noMoreDataFromCandidateList",
      "getCandidateCount",
      "getTotalCount",
      "currentPageSchema",
      "isFetchingGenericFilter",
      "filterDependencyObj",
      "selectedFilters",
      "getQueryResult",
      "isSelectedFilterUpdated",
    ]),
    basicFilterlength() {
      if (this.currentUserRole === Role.systemAdmin) {
        return 8;
      } else {
        return 6;
      }
    },
  },
  methods: {
    ...mapActions([
      "resetBackCount",
      "setCurrentPageSchema",
      "candidatesListAction",
      "initAllFilters",
      "updateSelectedFilter",
      "clearSearchFilterWithUpdateSearch",
      "addSearchTerm",
      "updateSelectedFilterFromFilterCode",
      "setCurrentNavigationCandidatesPage",
      "clearAllSelectedFilters",
      "getGenericSpecialitySearchBasedOnId",
      "getGenericSpecialitySearchBasedOnCode",
      "downloadCandidateList",
    ]),
    searchCallback(payload) {
      payload["type"] = "searchTerm";
      this.updateRouterURL(payload);
    },
    onBodyScroll(e) {
      if (this.noMoreDataFromCandidateList || this.isFetchingCandidates) return;
      if (
        e.target.clientHeight + e.target.scrollTop >=
        e.target.scrollHeight - 1
      ) {
        this.candidatesListAction({
          pagination: true,
          query: this.getQueryResult.queryUrl,
        });
      }
    },
    updateRouterURL(payload) {
      let appendAction = [];
      if (payload?.name === "specialitySearch")
        appendAction = [
          ...appendAction,
          this.getGenericSpecialitySearchBasedOnCode(payload),
        ];
      else appendAction = [...appendAction, this.updateSelectedFilter(payload)];
      Promise.all(appendAction).then((res) => {
        let path = `${this.$route.path}?${this.getQueryResult.routeUrl}`;
        this.$router.push({ path });
      });
    },
    filterAction(isAsync = false) {
      if (isAsync) {
        const appendAction = this.getGenericSpecialitySearchBasedOnId();
        Promise.all([appendAction]).then(async (res) => {
          await this.updateSelectedFilterFromFilterCode(
            this.$router.currentRoute.query
          );
          this.candidatesListAction({
            query: this.getQueryResult.queryUrl,
          });
        });
      } else {
        this.initAllFilters().then(async () => {
          await this.clearAllSelectedFilters();
          await this.updateSelectedFilterFromFilterCode(
            this.$router.currentRoute.query
          );
          await this.candidatesListAction({
            query: this.getQueryResult.queryUrl,
          });
        });
      }
    },
    triggerCandidateListDownload(){
      this.downloadCandidateList({
            query: this.getQueryResult.queryUrl,
          });
    }
  },
};
</script>
